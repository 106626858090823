/* ADD YOUR CUSTOM THEME */

/* GENERAL */

$dark: #18181e;
$darker: #131318;


#stroppiana {

    .font-serif {
        font-family: "Bodoni Moda", "Bodoni", serif;
    }

    &,
    body {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .pointer-event-none {
        pointer-events: none;
    }

    .opacity-25 {
        opacity: .25;
    }

    .opacity-50 {
        opacity: .5;
    }

    .opacity-75 {
        opacity: .75;
    }

    .body-menu-open {
        margin-top: 95px;
    }

    .btn {
        &.btn-dark {
            background-color: $dark;
            border-color: $dark;

            &:hover {
                background-color: transparent !important;
                color: $dark;
            }
        }
    }

    .bg-attachment-fixed {
        @media screen and (min-width: 1200px) {
            background-attachment: fixed;
        }
    }

    .section-big {
        padding: 180px 0;
    }

    .section-bigger {
        padding: 100px 0;

        @media screen and (min-width: 1200px) {
            padding: 200px 0;
        }
    }

    .filter-invert {
        filter: invert(1);
    }

    .bg-black {
        background-color: #000 !important;
    }

    .bg-dark {
        background-color: $dark !important;
    }

    .bg-darker {
        background-color: $darker !important;
    }

    .border-width-2 {
        border-width: 2px !important;
    }

    .z-index-1 {
        z-index: 1;
    }

    .z-index-2 {
        z-index: 2;
    }

    .z-index-3 {
        z-index: 3;
    }

    .text-dark-50 {
        color: rgba($color: $dark, $alpha: .72);
    }

    .container-fluid,
    .layout-container {
        @media screen and (min-width: 1600px) {
            padding-left: 3.3vw;
            padding-right: 3.3vw;
            max-width: 100%;
            margin: 0 auto;
        }

        @media screen and (min-width: 2000px) {
            padding-left: 3.3vw;
            padding-right: 3.3vw;
            max-width: 100%;
            margin: 0 auto;
        }

        @media screen and (min-width: 2500px) {
            padding-left: 3vw;
            padding-right: 3vw;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    body:not(#index) {

        .container-fluid,
        .layout-container {
            @media screen and (min-width: 2000px) {
                max-width: 1900px;
            }
        }
    }

    .container-fluid {
        @media screen and (min-width: 2000px) {
            max-width: 1900px;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /* MENU */



    .icon-menu {
        width: 21px;
    }




    #mobile_top_menu_wrapper {

        #_mobile_language_selector,
        #_desktop_language_selector {
            padding: 18px 21px 18px 23px;

            @media screen and (min-width: 768px) {
                padding: 18px 35px;
            }
        }



    }

    #_mobile_language_selector,
    #_desktop_language_selector {
        .dropdown-menu {
            a.dropdown-item {
                color: rgba(#fff, .85);

                &:hover {
                    background-color: rgba(#fff, .85) !important;
                    color: $dark !important;
                }

                &.active {
                    background-color: #fcfcfc !important;
                    color: $dark !important;
                }
            }
        }
    }

    #header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100vw;
        z-index: 9;
        background: transparent;
        transition: all .5s ease;


        .header__logo {
            .logo {
                margin: 0 auto;
                max-width: 180px;
                padding: 10px 0;

                &.logo-w {
                    display: block;
                    width: 100%;
                }

                &.logo-b {
                    display: none;
                    width: 0px;
                }
            }
        }

        #menu-icon {
            padding: 0;
            width: auto;
            min-width: auto;
        }

        a:not(.lang-link),
        i,
        span {
            color: white;
            font-size: .82rem;
        }

        .icon-menu {
            filter: invert(1);
        }



        .cart-products-count {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: white;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            display: block;
            color: #222222 !important;
            text-align: center;
            font-size: 10px;
            font-weight: bold;
            animation: pulse 2s infinite;
        }

        @keyframes pulse {
            0% {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
            }

            70% {
                box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
            }

            100% {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            }
        }


        &:hover,
        &.menu-scroll {
            background: #fff;

            .cart-products-count {
                background-color: $dark;
                color: #fff !important;
            }

            a:not(.lang-link),
            i,
            span {
                color: $dark !important;
                font-size: .82rem;
            }

            .header__logo {
                .logo {
                    &.logo-w {
                        display: none;
                    }

                    &.logo-b {
                        display: block;
                        width: 100%;
                    }
                }
            }

            .icon-menu {
                filter: invert(0);
            }
        }

        &.menu-scroll {
            position: fixed;

            .header-top {
                padding-top: 2px;
                padding-bottom: 2px;
            }

            box-shadow: 1px 0px 2px rgba($color: #000000, $alpha: .1);
        }
    }

    body:not(#index) {
        #header {
            &.menu-scroll {
                position: fixed;
            }

            background-color: #fff;
            position: relative;

            a:not(.lang-link),
            i,
            span {
                color: $dark !important;
            }

            .cart-products-count {
                background-color: #fff;
                color: $dark !important;
            }

            .icon-menu {
                filter: invert(0);
            }

            .header__logo {
                .logo {
                    &.logo-w {
                        display: none;
                    }

                    &.logo-b {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }


    /* MENU MOBILE */
    #mobile-amegamenu {
        .manufacturer-item {
            width: 45%;
            display: block;

            img {
                display: block;
                filter: invert(0);
                max-height: 40px;
                width: 100%;
                object-fit: contain;
                object-position: center;
                cursor: pointer;

                &:hover {
                    opacity: .5;
                }
            }

            .middle-side {
                display: none !important;
            }
        }
    }

    #mobile_top_menu_wrapper {
        &.show {
            svg {
                filter: invert(0);
            }

            .modal-content {
                background-color: #fff;
                color: $dark;
            }

            .modal-header {
                .close {
                    margin-left: 0;
                    margin-right: auto;
                    padding-left: 20px;

                    @media screen and (min-width: 768px) {
                        padding-left: 50px;
                    }

                    opacity: 1;
                    padding-top: 40px;
                    font-size: .78rem;
                    text-shadow: none;
                    font-weight: 400;
                    line-height: 1.5;
                    color: $dark;

                    img {
                        filter: invert(0);
                    }
                }
            }


            .mobile_item_wrapper,
            .mobile-item-nodropdown {

                @media screen and (max-width: 767.9px) {
                    padding: 0;
                }


                color: $dark;

                &.open {
                    .amenu-link {
                        color: $dark;
                    }
                }

                .amenu-link {
                    color: $dark;
                    font-size: 18px;
                    padding: 18px 21px 18px 23px;
                    text-transform: none;
                    font-weight: 400;

                    &:hover {
                        color: $dark;
                    }
                }
            }

            .adropdown-mobile {
                background-color: #fcfcfc;
                padding-left: 35px;

                @media screen and (min-width: 768px) {
                    padding-left: 30px;
                    opacity: 0;
                    transition: all .5s ease;
                    left: -200px;
                }

                &.menu-active {
                    max-height: 100vh;
                    overflow-y: auto;

                    @media screen and (min-width: 768px) {
                        position: fixed;
                        left: 349px;
                        opacity: 1;
                    }

                    .dropdown-content {
                        padding-top: 30px;

                        .category-item {
                            padding-top: 18px;

                            p,
                            a {
                                font-size: 16px;
                                color: $dark;
                            }
                        }
                    }
                }
            }
        }
    }

    /* SLIDER */
    .homepage-cover {
        position: relative;
        width: 100%;
        max-width: 100vw;
        min-height: 100vh;
        overflow: hidden;

        &>img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            animation: kenBuns 80s ease;
            object-fit: cover;
            object-position: center;
        }

        .row {
            position: relative;
            z-index: 3;
            height: 100%;
            min-height: 100vh;
        }

        .scroll-down {
            position: absolute;
            bottom: 10px;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            text-align: center;
            z-index: 4;
            display: flex;
            justify-content: center;
            opacity: .72;
            transition: all .5s ease;

            div {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 0 auto;
                transition: all .5s ease;

                img {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }

            &:hover {
                opacity: 1;

                div {
                    border-width: 2px !important;
                }
            }

        }
    }

    @keyframes kenBuns {
        0% {
            transform-origin: bottom left;
            transform: scale(1.0);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }


    /* SEARCH */

    #header {
        .search-modal {
            display: none;

            &.active {
                display: block;
                position: absolute;
                left: 0;
                right: auto;
                top: 35px;
                width: 320px;
                max-width: 70vw;

                .search-widget {
                    width: 320px;
                    max-width: 70vw;
                }
            }
        }

        .search-widget__group {
            .search-widget__btn {
                i {
                    font-size: 20px;
                    color: $dark !important;
                }
            }
        }
    }

    .autocomplete-suggestion {
        display: flex;
        align-items: center;

        img {
            object-fit: contain;
            object-position: center;

            @media screen and (min-width: 1440px) {
                max-width: 62px;
                max-height: 62px;
            }
        }
    }

    /* FOOTER */

    footer {
        .footer-container {
            padding: 100px 0;

            .footer__title {
                font-size: 16px;
                color: white;
                margin-bottom: 12px;
            }

            &,
            li,
            p,
            a {
                font-size: .82rem;
                color: rgba($color: #fff, $alpha: .8) !important;

                &:hover {
                    color: rgba($color: #fff, $alpha: 1) !important;
                }
            }

            li,
            p {
                margin-bottom: 8px;
            }
        }
    }

    .whatsapp-widget {
        position: fixed;
        bottom: 70px;
        right: 15px;
        transition: .5s;
        z-index: 999;

        &>div {
            min-width: 50px;
            height: 50px;
            background-color: #25d366;
            box-shadow: 0 1px 4px rgba(0, 0, 0, .2);

            img {
                display: block;
            }

            &:hover {
                background-color: #19ac50;
            }
        }
    }

    .legal {
        font-size: .82rem;
    }


    /* PRODUCT */

    #product {

        .layout-container {
            margin: 0 !important;
            padding: 0 !important;
            max-width: 100% !important;
        }

        #product-details {
            .product-manufacturer {
                img {
                    max-width: 100px;
                    margin-bottom: 25px;
                }
            }
        }

        .btn {
            &.slick-next {
                i {
                    margin-left: auto;
                }
            }
        }

        .btn-zoom {
            @media screen and (max-width: 991px) {
                display: block;
            }

            i {
                font-size: 30px;
                line-height: 35px;
                width: 35px;
                height: 35px;
                color: white;
                background-color: rgba($color: #000000, $alpha: .45);
                border-radius: 50px;
            }
        }
    }

    .bg-new {
        background-color: #e6ffe6
    }

    .bg-used {
        background-color: #f3f3f3
    }

    .product-title {
        font-size: 0.94rem;
    }

    /* CAROUSEL */
    .owl-nav {
        button {
            width: auto;
            height: auto;
            padding: 0;
            margin-left: 0;
            margin-right: 0;
            background-color: transparent !important;
            opacity: 1;
            margin-top: -100px;

            &.owl-prev .carousel-prev,
            &.owl-next .carousel-next {
                background-size: contain;
                width: 32px;
                height: 32px;
                background-color: #9d9d9d;
                border: 1px solid #9d9d9d;
                padding: 4px;
                border-radius: 50%;

                img {
                    filter: invert(1);
                }

                &:hover {
                    background-color: white;
                    border: 1px solid white;

                    img {
                        filter: invert(0);
                    }
                }
            }
        }
    }

    .owl-dots {
        padding: 0;
        margin: 0;

        .owl-dot {
            &.active {
                span {
                    background-color: white;
                }
            }

            span {
                margin-bottom: 0px;
                margin-top: 0px;
            }
        }
    }

    .arpl-group {
        .container-fluid {
            .arpl-section {
                padding: 0;
                border: 1px solid $white;
                background-color: $white;

                .card {
                    background-color: transparent;
                }
            }
        }
    }

    /* BLOG */
    .card-blog {
        transition: all .5s ease;
    }

    .prestablog_pagination {
        text-align: center;

        span {
            &.current {
                border: 1px solid $dark;
                background-color: $dark;
            }
        }
    }

    #prestablogfront {
        background-color: transparent;
        border: none !important;

        time.date {
            &::before {
                display: none;
            }
        }

        .info_blog {
            &::before {
                display: none;
            }
        }
    }

    #prestablog_article {
        font-size: 2.188rem;
    }

    /* FILTER */
    #amazzing_filter {
        @media screen and (min-width: 1200px) {
            padding-right: 40px;
        }

        .title_block,
        .af_subtitle,
        .name {
            color: $dark !important;
        }

        .af_filter {
            border-bottom: 1px solid #3C3C3C;
        }
    }

    #select-sort-order {
        background-color: $light;
        color: $dark;
    }

    /* BRANDS */

    #manufacturer {
        .brand-img {
            img {
                cursor: pointer;

                &:hover {
                    opacity: .5;
                }
            }
        }
    }

    /* HOMEPAGE */
    .home-brands {
        img {
            aspect-ratio: 1;
            object-fit: contain;
            width: 100%;
            cursor: pointer;
            max-width: 100px;
            text-align: center;
            margin: 0 auto;
            display: block;

            &:hover {
                opacity: .5;
            }
        }
    }

    .promo {
        aspect-ratio: 3/1.4;
    }

    .img-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .background-mobile {
        background-size: cover;
        background-color: rgba(#fff, .85);
        background-blend-mode: lighten;
        background-position: center;

        @media screen and (min-width: 992px) {
            background: none !important;
        }
    }

    #jsCarousel {

        .carousel-prev,
        .carousel-next {
            background-size: contain;
            width: 32px;
            height: 32px;
            background-color: #9d9d9d;
            border: 1px solid #9d9d9d;
            padding: 4px;
            border-radius: 50%;

            img {
                filter: invert(1);
            }

            &:hover {
                background-color: white;
                border: 1px solid white;

                img {
                    filter: invert(0);
                }
            }
        }

        .carousel-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -10px;
        }

        .carousel-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -10px;
        }
    }

    /* ARP CAROUSEL */
    .arpl-group {

        &.arpl-group-tabbed,
        &.arpl-non-tabbed-group {
            .nav-item {
                .nav-link {
                    border: 0 !important;
                    margin-bottom: 0px;

                    &.active {
                        border-bottom: 2px solid $dark !important;

                        h2 {
                            font-weight: bold;
                        }
                    }
                }
            }

            .arpl-carousel-products {
                .product-miniature {
                    flex: none;
                    max-width: 100%;
                }
            }
        }
    }

    /* CMS */
    #cms {
        .layout-container {
            max-width: 100% !important;
            margin: 0 auto !important;
            padding: 0 !important;
        }
    }

    /* FAQ */

    #module-bonfaq-faq {
        .layout-container {
            max-width: 100% !important;
            margin: 0 auto !important;
            padding: 0 !important;
        }
    }

    /* AUTH */

    .social-label-login-only {
        display: none;
    }

    #authentication,
    #registration,
    #password {

        .social-label-login-only {
            display: block;
        }        

        .authentication-card {
            margin-top: 100px!important;
            margin-bottom: 100px!important;
        }

        .layout-container {
            max-width: 100% !important;
            & > .row{
                padding-top: 0;
            }
        }

        .section-newsletter {
            display: none;
        }

        #fbpsc {
            max-width: 450px;
            margin: 0 auto;
            padding: 0;

            .btn-social {
                display: block;
                margin-top: 0px;
                width: 100%;

                @media screen and (min-width:768px) {
                    width: auto;
                }

                max-width: 100%;
                min-width: none;
            }
        }

        .auth-socil-label {
            display: block !important;
        }

        .password-strength-feedback {
            display: block !important;

            i {
                color: #dc3545;
            }
        }
    }

    .password-strength-feedback {
        display: block !important;

        i {
            color: #dc3545;
        }
    }

    /* SOCIAL LOGIN */

    #fbpsc {
        padding-bottom: 50px;
        background: none !important;
        border: none !important;

        .btn-connect {
            padding: 14px 20px;
            text-transform: uppercase;
            font-size: .7875rem;
            font-weight: 700;
            min-width: 120px;
            box-shadow: none;
            border-radius: 50px;

            &.btn-facebook {
                background-color: transparent;
                border: 1px solid #3b5998;
                color: #3b5998;

                &> :first-child {
                    filter: invert(33%) sepia(9%) saturate(3316%) hue-rotate(183deg) brightness(60%) contrast(93%);
                }

                span {
                    color: #3b5998;
                }

                &:hover {
                    background-color: #3b5998;
                    border: 1px solid #3b5998;
                    color: #ffffff;

                    &> :first-child {
                        filter: none;
                    }

                    span {
                        color: #fff;
                    }
                }
            }

            &.btn-google,
            &.btn-google-default {
                background-color: transparent;
                border: 1px solid #dd4b39;
                color: #dd4b39;

                span {
                    color: #dd4b39;
                }

                &:hover {
                    background-color: #dd4b39;
                    border: 1px solid #dd4b39;
                    color: #ffffff;

                    &> :first-child {
                        filter: none;
                    }

                    span {
                        color: #fff;
                    }
                }
            }

            &> :first-child {
                top: 12px;
            }

            span {
                font-size: .77rem;
            }
        }
    }

    /* CHECKOUT */
    .personal-information-text {
        @media screen and (min-width: 1200px) {
            min-height: 40px;
        }
    }

}